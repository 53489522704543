import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import publishedApi from 'api/publishedApi';

const pollingSlice = createSlice({
  name: 'publish',
  initialState: {
    isLoadingUpdatePrice: false,
    status: 'idle',
    error: null,
    time: '',
  },
  reducers: {
    stopGetStatusUpdatePrice(state) {
      state.isLoadingUpdatePrice = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updatePriceDataPublish.pending, (state) => {
        state.isLoadingUpdatePrice = true;
        state.error = null;
      })
      .addCase(updatePriceDataPublish.fulfilled, (state, action) => {})
      .addCase(updatePriceDataPublish.rejected, (state, action) => {
        state.isLoadingUpdatePrice = false;
        state.error = action.payload;
      })

      .addCase(getStatusUpdatePriceData.fulfilled, (state, action) => {
        state.status = action.payload?.status;
        if (action.payload?.status === 'success') {
          state.isLoadingUpdatePrice = false;
          state.time = action.payload?.updateAt;
        }
        if (action.payload?.status === 'error') {
          state.isLoadingUpdatePrice = false;
          state.time = action.payload?.lastTimeSuccess;
        }
      })
      .addCase(getStatusUpdatePriceData.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoadingUpdatePrice = false;
        state.status = 'error';
      });
  },
});

export const updatePriceDataPublish = createAsyncThunk(
  'publish/updatePriceDataPublish',
  async (_, { rejectWithValue }) => {
    try {
      const response = await publishedApi.updatePriceDataPublish();
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const getStatusUpdatePriceData = createAsyncThunk(
  'publish/getStatusUpdatePriceData',
  async (_, { rejectWithValue }) => {
    try {
      const response = await publishedApi.getStatusUpdatePriceData();
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const { stopGetStatusUpdatePrice } = pollingSlice.actions;
export default pollingSlice.reducer;
