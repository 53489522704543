import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import rawDataApi from 'api/rawDataApi';

// Slice quản lý logic API dài và polling
const pollingSlice = createSlice({
  name: 'polling',
  initialState: {
    isLongRunningApiInProgress: false,
    status: 'idle', // idle | pending | completed
    error: null,
    time: '',
  },
  reducers: {
    stopPolling(state) {
      state.isLongRunningApiInProgress = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(startLongRunningApi.pending, (state) => {
        state.isLongRunningApiInProgress = true;
        state.error = null;
      })
      .addCase(startLongRunningApi.fulfilled, (state, action) => {})
      .addCase(startLongRunningApi.rejected, (state, action) => {
        state.isLongRunningApiInProgress = false;
        state.error = action.payload;
      })

      .addCase(pollStatusApi.fulfilled, (state, action) => {        
        state.status = action.payload?.status;
        if (action.payload?.status === 'success') {
          state.isLongRunningApiInProgress = false;
          state.time = action.payload?.updateAt;
        }
        if (action.payload?.status === 'in-progress') {
          state.isLongRunningApiInProgress = true;
        }
        if (action.payload?.status === 'error') {
          state.isLongRunningApiInProgress = false;
          state.time = action.payload?.lastTimeSuccess;
        }
      })
      .addCase(pollStatusApi.rejected, (state, action) => {
        state.error = action.payload;
      });
  },
});

// Async thunk để gọi API dài
export const startLongRunningApi = createAsyncThunk(
  'polling/startLongRunningApi',
  async (_, { rejectWithValue }) => {
    try {
      const response = await rawDataApi.getNewData();      
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

// Async thunk để polling API kiểm tra trạng thái
export const pollStatusApi = createAsyncThunk(
  'polling/pollStatusApi',
  async (_, { rejectWithValue }) => {
    try {
      const response = await rawDataApi.getStatusRawData();      
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const { stopPolling } = pollingSlice.actions;
export default pollingSlice.reducer;
