import { notification } from 'antd';
import axios from 'axios';
import toast from 'react-hot-toast';

import { ROOT_API } from '../constants/common';
import { getRefreshToken, getUser, setUser } from '../utils/auth';
import authApi from './authApi';

notification.config({
  maxCount: 1,
});

const axiosClient = axios.create({
  baseURL: ROOT_API,
  headers: {
    'Content-Type': 'application/json',
  },
  timeout: 50000,
});

// Add a request interceptor
// axios.interceptors.request.use(
//   function (config) {
//     // Do something before request is sent
//     return config;
//   },
//   function (error) {
//     // Do something with request error
//     return Promise.reject(error);
//   },
// );

// Add a request interceptor
axiosClient.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    const token = getUser()?.accessToken;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  function (error) {
    // Do something with request error
    
    return Promise.reject(error);
  },
);

// Add a response interceptor
axiosClient.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response?.data;
  },
  async function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if(error?.response?.data?.message ==="Unauthorized"){
      const refreshToken = getRefreshToken();
      try {
        const response = await authApi.refreshToken({
          refreshToken: refreshToken,
          username: getUser()?.user.username
        });
  
        if (response.data.statusCode === 200) {
          setUser(response.data.data);
          toast.success(response.data.message);

          // Gửi lại yêu cầu ban đầu với token mới
          const originalRequest = error.config;
          originalRequest.headers.Authorization = `Bearer ${response.data.data.accessToken}`;
          return axiosClient(originalRequest); // Gửi lại yêu cầu ban đầu
        }
      } catch (error) {
        const text = error.response.data.message || error.response.data.message[0] || error.message;
        toast.error(text);
      }
    }
    return Promise.reject(error);
  }
);

export default axiosClient;
