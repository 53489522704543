import axiosClient from './axiosClient';

const authApi = {
  login: (data) => {
    const response = axiosClient.post(`/v1/api/auth/signin`, data);
    return response;
  },
  refreshToken: (params) => {
    const response = axiosClient.post(`/v1/api/auth/refresh-token`, params);
    return response;
  },
};

export default authApi;
