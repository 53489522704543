import { Button, Form, Input } from 'antd';
import authApi from 'api/authApi';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { getErrorMessage } from 'utils/getErrorMessage';
import { setUser } from '../../../utils/auth';
import ImageSignin from '../../assets/images/image_signin.png';

export function Login() {
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const onFinish = async () => {
    form.validateFields().then(async (values) => {
      try {
        setIsLoading(true);
        const response = await authApi.login(values);
        if (response.statusCode === 200) {
          setUser(response.data);
          setIsLoading(false);
          navigate('/raw-data');
          toast.success(response.message);
        }
      } catch (error) {
        setIsLoading(false);
        const text = error.response.data.message || error.response.data.message[0] || error.message;
        const message = getErrorMessage(text);
        toast.error(message);
      }
    });
  };

  return (
    <Container>
      <SmallContainer>
        <div style={{ width: '40%' }}>
          <FormStyled form={form} onFinish={onFinish} layout="vertical">
            <HeaderStyled>Đăng nhập</HeaderStyled>

            <Form.Item hidden>
              <Input
                type="text"
                name="fakeUsername"
                autoComplete="off"
                style={{ display: 'none' }}
              />
            </Form.Item>
            <Form.Item hidden>
              <Input
                type="password"
                name="fakePassword"
                autoComplete="new-password"
                style={{ display: 'none' }}
              />
            </Form.Item>

            <Form.Item
              name={'account'}
              label={'Tên tài khoản'}
              rules={[
                {
                  required: true,
                  message: 'Vui lòng nhập tên tài khoản!',
                },
              ]}
            >
              <Input placeholder="Nhập tên tài khoản..." />
            </Form.Item>

            <Form.Item
              name={'password'}
              label={'Mật khẩu'}
              rules={[
                {
                  required: true,
                  message: 'Vui lòng nhập mật khẩu!',
                },
              ]}
            >
              <Input.Password placeholder="Nhập mật khẩu..." autocomplete="new-password" />
            </Form.Item>

            <CustomButtonStyled type="primary" htmlType="submit" loading={isLoading}>
              ĐĂNG NHẬP
            </CustomButtonStyled>
          </FormStyled>
        </div>

        <div style={{ width: '50%' }}>
          <img
            src={ImageSignin}
            alt=""
            style={{ width: '100%', height: '100%', objectFit: 'contain' }}
          />
        </div>
      </SmallContainer>
    </Container>
  );
}

export const Container = styled.div`
  background: rgb(238, 174, 202);
  background: radial-gradient(circle, rgba(238, 174, 202, 1) 0%, rgba(148, 187, 233, 1) 100%);
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const SmallContainer = styled.div`
  background: white;
  width: 900px;
  height: 500px;
  padding: 20px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  justify-content: space-between;
`;
export const FormStyled = styled(Form)`
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 20px;
  border-radius: 10px;
  width: 100%;
  height: 100%;
`;
export const HeaderStyled = styled.div`
  font-weight: bold;
  font-size: 26px;
  margin-bottom: 20px;
`;
export const CustomButtonStyled = styled(Button)`
  width: 100%;
  background: white;
  color: gray;
  font-weight: bold;
  font-size: 16px;
  letter-spacing: 2px;
  margin-top: 20px;
  border: 1px solid #d9d9d9;
`;
