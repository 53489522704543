import queryString from 'query-string';
import axiosClient from './axiosClient';

const publishedApi = {
  getListPublished: (query, payload) => {
    const response = axiosClient.post(
      `/v1/api/diamond/search?${queryString.stringify(query)}`,
      payload,
    );
    return response;
  },
  unpublishData: (params) => {
    const response = axiosClient.post(`/v1/api/diamond/action-items`, params);
    return response;
  },
  exportData: (params,key) => {    
    const response = axiosClient.post(`/v1/api/diamond/export-diamond/${key==='1'?'xlsx':'csv'}`, params,{
      responseType: 'blob',
    },
);
    return response;
  },
  getStatusUpdatePriceData: () => {
    const response = axiosClient.get(`/v1/api/diamond/job-log/auto-update-publish-data`);
    return response;
  },
  updatePriceDataPublish: () => {
    const response = axiosClient.post(`/v1/api/diamond/update-publish-data`);
    return response;
  },
};

export default publishedApi;
